<!--
 * @Author: dingguowei
 * @Date: 2023-06-13 10:13:52
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-07-07 16:02:30
-->
<!-- 用户钱包 -->
<template>
  <div class="myWallet">
    <div class="head">
      <div class="item">
        <p>我的资产</p>
        <div class="wallet">
          <div class="Info">
            <p style="font-size: 0.8rem">总资产</p>
            <p style="font-size: 1.2rem">
              ￥{{
                money.totalAmount && money.totalAmount !== null
                ? money.totalAmount
                : 0
              }}
            </p>
          </div>
          <div class="btn">
            <el-button round size="mini" style="margin: 0 10px 10px 0" @click="cashOut">
              去提现</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="body">
      <el-table :data="walletData" :key="tableKey" style="width: 100%; font-size: 0.8rem; flex: 1" height="100%"
        v-loading="loading">
        <el-table-column label="序号" type="index" width="80" align="center" :show-overflow-tooltip="true">
        </el-table-column>

        <el-table-column label="任务名称" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.missionTitle }}
          </template>
        </el-table-column>
        <el-table-column label="订单编号" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.orderNo ? scope.row.orderNo : "暂无编号" }}
          </template>
        </el-table-column>
        <el-table-column label="创建时间" width="160" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.createdOn }}
          </template>
        </el-table-column>
        <el-table-column label="可提现时间" width="160" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.cashOn }}
          </template>
        </el-table-column>

        <el-table-column label="获得金额" :show-overflow-tooltip="true" width="100">
          <template slot-scope="scope">
            {{ scope.row.changeAmount }}
          </template>
        </el-table-column>

        <el-table-column label="交易类别" align="center" width="140">
          <template slot-scope="scope">
            {{ typetext(scope.row.type) }}
          </template>
        </el-table-column>
        <el-table-column label="状态" align="center" width="120">
          <template slot-scope="scope">
            {{ statusText(scope.row.status) }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination background layout="prev, pager, next" :total="total"
      style="margin-left: AUTO; margin-top: 10px; display: block" :pager-count="7" small @current-change="zbWallet"
      :current-page.sync="pageNo" :page-size="pageSize">
    </el-pagination>
  </div>
</template>

<script>
import { zbWallet, getmoney } from "@/api/user";
import store from "@/store";
export default {
  components: {},
  data() {
    return {
      walletData: [],
      pageNo: 1,
      pageSize: 10,
      tableKey: true,
      loading: false,
      money: {},
      total: 0,
    };
  },
  created() { },
  mounted() {
    this.$nextTick(() => {
      this.getmoney();
    });
    this.zbWallet();
  },
  watch: {},
  computed: {
    statusText() {
      return function (val) {
        if (val === 0) {
          return "不可提现";
        } else if (val === 1) {
          return "可提现";
        } else if (val === 2) {
          return "已提现";
        }
      };
    },
    typetext() {
      return function (val) {
        if (val === 0) {
          return "品牌方首付款";
        } else if (val === 1) {
          return "品牌方尾付款";
        } else if (val === 2) {
          return "任务互动奖励";
        } else {
          return "用户提现"
        }
      };
    },
    userInfo() {
      return store.state.user.userInfo;
    },
  },
  methods: {
    zbWallet() {
      zbWallet({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      }).then((res) => {
        this.tableKey = !this.tableKey;
        this.total = res.data.total;
        this.walletData = res.data.records;
      });
    },
    async getmoney() {
      await getmoney().then((res) => {
        this.money = res.data;
      });
    },
    cashOut() {
      if (this.userInfo.idCard && this.userInfo.alipayAccount && this.userInfo.realName) {
        this.$message({
          message: "请联系管理员打款",
          type: "success",
        });
      } else {
        this.$message({
          message: "请完善个人账号信息",
          type: "warning",
        });
      }

    },
  },
};
</script>
<style lang="scss" scoped>
.myWallet {
  box-sizing: border-box;
  padding: 1rem 2rem;
  display: flex;

  flex-direction: column;
  color: #000;

  .head {
    display: flex;

    .item {
      width: 20rem;

      & p {
        font-size: 0.9rem;
        font-weight: 600;
      }

      .wallet {
        // width: 100%;
        margin-top: 4px;
        height: 8rem;

        background-image: url("../../assets/user/my.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        color: #fff;
        display: flex;

        .Info {
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: center;

          & p {
            // flex: 1;
            line-height: 180%;
            display: flex;
            padding-left: 2rem;
          }
        }

        .btn {
          display: flex;
          align-items: flex-end;
        }
      }
    }
  }

  .body {
    flex: 1;
    padding-top: 10px;
  }
}
</style>